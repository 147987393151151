import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Fade } from "react-reveal";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import Select from 'react-select';

import { GenderOptions } from "../Apply/DropdownOptions/GenderOptions";
import { StateOptions } from "../Apply/DropdownOptions/StateOptions";
import { BleedingDisorderTypeOptions } from "../Apply/DropdownOptions/BleedingDisorderTypeOptions";
import { BleedingDisorderRelationshipOptions } from "../Apply/DropdownOptions/BleedingDisorderRelationshipOptions";
import { BleedingDisorderSeverityOptions } from "../Apply/DropdownOptions/BleedingDisorderSeverityOptions";

const numbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
const symbols = [
    '<', '>', '&', '"', "'", '/', '\\', ';', '%', '$', '#', '{', '}', '[', ']',
    '(', ')', '=', '+', '-', '*', '`', '^', '|', '~', '!', '?', ':', '.', ',', '@'
];

export default function EventSigninForm(props) {
    const eventID = props.eventID;
    const [guestListOptions, setGuestListOptions] = useState(() =>
        props.guestList.map(guest => ({
            label: `${guest.firstName} ${guest.lastName}`,
            value: guest.email
        }))
    );
    const [selectedGuestListOption, setSelectedGuestListOption] = useState(null);
    const [selectedEntry, setSelectedEntry] = useState(null);

    // General
    const [onlineSignup, setOnlineSignup] = useState(null);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [birthday, setBirthday] = useState("");
    const [gender, setGender] = useState(null);
    const [phone, setPhone] = useState("");

    // Address
    const [streetAddress, setStreetAddress] = useState("");
    const [streetAddress2, setStreetAddress2] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState(null);
    const [zip, setZip] = useState("");

    // Bleeding Disorder
    const [bleedingDisorder, setBleedingDisorder] = useState(null);
    const [bleedingDisorderRelationship, setBleedingDisorderRelationship] = useState("");
    const [bleedingDisorderType, setBleedingDisorderType] = useState("");
    const [bleedingDisorderSeverity, setBleedingDisorderSeverity] = useState("");

    // Etc.
    const [groupMembers, setGroupMembers] = useState("");

    // True false
    const [photoOptIn, setPhotoOptIn] = useState(null);
    const [sponsorContactOptIn, setSponsorContactOptIn] = useState(null);

    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const selectStyles = {
        option: (provided, state) => ({
            ...provided,
            fontWeight: state.isSelected ? "bold" : "normal",
            fontSize: props.width <= 1260 ? "1.2rem" : "1.4rem",
            color: "#2d3436",
            backgroundColor: "#fff",
            "&:hover": {
                backgroundColor: "#f0f0f0",
            },
        }),
        control: (provided, state) => ({
            ...provided,
            borderColor: state.isFocused ? "#2d3436" : "#fff",
            margin: "12px 0 12px",
            height: props.width <= 1260 ? "48px" : "55px",
        }),
    };

    // useEffect with cleanup to handle component unmount
    useEffect(() => {
        return () => {
            clearForm();
        };
    }, []); // Empty dependency array ensures it only runs on unmount

    // Functions

    const verifyIsNumber = (input) => {
        for (var i = 0; i < input.length; i++) {
            if (!numbers.includes(input[i])) {
                return false;
            };
        };
        return true;
    };

    // Resets all states to their default values
    const clearForm = () => {
        setFirstName("");
        setLastName("");
        setEmail("");
        setBirthday("");
        setGender(null);
        setPhone("");
        setStreetAddress("");
        setStreetAddress2("");
        setCity("");
        setState(null);
        setZip("");
        setBleedingDisorder(null);
        setBleedingDisorderRelationship("");
        setBleedingDisorderType("");
        setBleedingDisorderSeverity("");
        setGroupMembers("");
        setPhotoOptIn(null);
        setSponsorContactOptIn(null);
    };

    const submitFormAndResetComponent = (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('firstName', firstName);
        formData.append('lastName', lastName);
        formData.append('email', email);
        formData.append('birthday', birthday);
        formData.append('phone', phone);
        formData.append('gender', gender.value);
        formData.append('city', city);
        formData.append('streetAddress', streetAddress);
        formData.append('streetAddress2', streetAddress2);
        formData.append('state', state.value);
        formData.append('ZIP', zip);
        formData.append('bleedingDisorder', bleedingDisorder);
        formData.append('bleedingDisorderRelationship', bleedingDisorder ? bleedingDisorderRelationship.value : "None");
        formData.append('bleedingDisorderType', bleedingDisorder ? bleedingDisorderType.value : "None");
        formData.append('bleedingDisorderSeverity', bleedingDisorder ? bleedingDisorderSeverity.value : "None");
        formData.append('groupMembers', groupMembers);
        formData.append('photoOptIn', photoOptIn);
        formData.append('sponsorContactOptIn', sponsorContactOptIn);
        formData.append('eventID', eventID);
        axios.post('/api/update-attending-list-for-event', formData, {
            withCredentials: true,
        }).then((res) => {
            if (res.data.status) {
                const data = {
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    birthday: birthday,
                    gender: gender.value,
                    phone: phone,
                    streetAddress: streetAddress,
                    streetAddress2: streetAddress2,
                    city: city,
                    state: state.value,
                    bleedingDisorder: bleedingDisorder,
                    bleedingDisorderRelationship: bleedingDisorderRelationship,
                    bleedingDisorderType: bleedingDisorderType,
                    bleedingDisorderSeverity: bleedingDisorderSeverity,
                    groupMembers: groupMembers,
                    photoOptIn: photoOptIn,
                    sponsorContactOptIn: sponsorContactOptIn,
                };
                props.updateSelectedGuestList(data);
                props.updateDisplay();
                clearForm();
            } else {
                setError(true);
                setErrorMessage(res.data.message);
            }
        })
    }

    const filterNumbersAndSymbols = (input) => {
        for (var i = 0; i < input.length; i++) {
            if (numbers.includes(input[i]) || symbols.includes(input[i])) {
                return false;
            }
        }

        return true;
    };

    // General Verifications

    const verifyFirstName = () => {
        if (firstName.length < 2 || firstName.length > 20) {
            return false;
        }

        return true;
    };

    const verifyLastName = () => {
        if (lastName.length < 2 || lastName.length > 20) {
            return false;
        }

        return true;
    };

    const verifyBirthday = () => {
        if (birthday.length === 10) {
            return true;
        };

        return false;
    };

    const verifyPhone = () => {
        if (phone.length === 14) {
            return true;
        };

        return false;
    };

    const verifyGroupMembers = () => {
        if (groupMembers.length < 1 || groupMembers.length > 20) {
            return false;
        }

        return true;
    }

    const verifyEmail = () => {
        if (email.includes('@') && email.includes('.') && email.length > 6 && email.length < 45) {
            return true;
        }
        return false;
    }

    const verifyStreetAddress = () => {
        if (streetAddress.length > 0 && streetAddress.length < 50) {
            return true;
        };

        return false;
    };

    const verifyCity = () => {
        if (city.length > 0 && city.length < 30) {
            return true;
        };

        return false;
    };

    const verifyZip = () => {
        if (zip.length === 5 && verifyIsNumber(zip)) {
            return true;
        };

        return false;
    };



    // Renders

    const renderGuestList = () => {
        if (onlineSignup) {
            return (
                <>
                    <Fade>
                        <label>Select Your Online Sign In</label>
                        <Select
                            styles={selectStyles}
                            menuPortalTarget={document.body}
                            menuPosition={'fixed'}
                            value={selectedGuestListOption}
                            onChange={(option) => {
                                // Update option state if not done
                                if (selectedGuestListOption === null || selectedGuestListOption.value !== option.value) {
                                    setSelectedGuestListOption(option);
                                }

                                // Find and update event object if not updated
                                const matchingListEntry = props.guestList.find(entry => entry.email === option.value);
                                if (matchingListEntry && matchingListEntry !== selectedEntry) {
                                    setSelectedEntry(matchingListEntry);
                                    setFirstName(matchingListEntry.firstName);
                                    setLastName(matchingListEntry.lastName);
                                    setEmail(matchingListEntry.email);
                                }
                            }} options={guestListOptions} />
                    </Fade>
                </>
            )
        }
    }

    const renderBleedingDisorderQuestions = () => {
        if (bleedingDisorder) {
            return (
                <div className="event-signin-form-section">
                    <div className="section-item">
                        <label>
                            Relationship to Bleeding Disorder: {bleedingDisorderRelationship.length === 0 ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                            <Select
                                styles={selectStyles}
                                menuPortalTarget={document.body}
                                menuPosition={'fixed'}
                                value={bleedingDisorderRelationship}
                                onChange={(option) => setBleedingDisorderRelationship(option)} options={BleedingDisorderRelationshipOptions} />
                        </label>
                    </div>

                    <div className="section-item">
                        <label>
                            Type: {bleedingDisorderType.length === 0 ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                            <Select
                                styles={selectStyles}
                                menuPortalTarget={document.body}
                                menuPosition={'fixed'}
                                value={bleedingDisorderType}
                                onChange={(option) => setBleedingDisorderType(option)} options={BleedingDisorderTypeOptions} />
                        </label>
                    </div>

                    <div className="section-item">
                        <label>
                            Severity: {bleedingDisorderSeverity.length === 0 ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                            <Select
                                styles={selectStyles}
                                menuPortalTarget={document.body}
                                menuPosition={'fixed'}
                                value={bleedingDisorderSeverity}
                                onChange={(option) => setBleedingDisorderSeverity(option)} options={BleedingDisorderSeverityOptions} />
                        </label>
                    </div>
                </div>
            )
        }
    }

    const renderError = () => {
        if (error) {
            return (
                <>
                    <div className="error">
                        <h2 className="error">{errorMessage}</h2>
                    </div>
                </>
            )
        }
    }

    const renderSubmitButton = () => {
        if (verifyFirstName() && verifyLastName() && verifyEmail() && verifyGroupMembers() && verifyCity()
            && state !== null && photoOptIn !== null && sponsorContactOptIn !== null) {
            return (
                <>
                    <button className="submit-button approved-button" onClick={submitFormAndResetComponent}>
                        Submit
                    </button>
                </>
            )
        }

        return (
            <>
                <button className="submit-button denied-button">
                    Submit
                </button>
            </>
        )
    }

    return (
        <>
            <div className="event-signin-form-section">
                <h2 className="header bleeding-disorder-question">Did you Sign Up Online?</h2>
                <div className="apply-buttons-container">
                    <button className={onlineSignup === false ?
                        "apply-button declined no" : "apply-button no"}
                        onClick={() => {
                            setOnlineSignup(false);
                        }}>No <i class="fa-solid fa-xmark"></i></button>

                    <button className={onlineSignup === true ?
                        "apply-button approved yes" : "apply-button yes"}
                        onClick={() => {
                            setOnlineSignup(true);
                        }}>Yes <i class="fa-solid fa-check"></i></button>
                </div>

                {renderGuestList()}

                <div className="event-signin-form-section">
                    <div className="event-signin-form-section-list">
                        <div className="section-item">
                            <label for="firstName">
                                First Name: {!verifyFirstName() ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                                <InputText id='firstName' value={firstName} onChange={(event) => {
                                    if (filterNumbersAndSymbols(event.target.value)) {
                                        setFirstName(event.target.value);
                                    }
                                }} />
                            </label>
                        </div>

                        <div className="section-item">
                            <label for="lastName">
                                Last Name: {!verifyLastName() ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                                <InputText id='lastName' value={lastName} onChange={(event) => {
                                    if (filterNumbersAndSymbols(event.target.value)) {
                                        setLastName(event.target.value);
                                    }
                                }} />
                            </label>
                        </div>

                        <div className="section-item">
                            <label for="email">
                                Email: {verifyEmail() ? <i class="fa-solid fa-check green-check"></i> : <i class="fa-thin fa-asterisk red-asterisk" />}
                                <InputText id='email' value={email} onChange={(event) => setEmail(event.target.value)} />
                            </label>
                        </div>

                        <div className="section-item">
                            <label for="birthday">
                                Birthday: {verifyBirthday() ? <i class="fa-solid fa-check green-check"></i> : <i class="fa-thin fa-asterisk red-asterisk" />}
                                <InputMask mask="99/99/9999" slotChar="mm/dd/yyyy" value={birthday} onChange={(event) => {
                                    setBirthday(event.target.value);
                                }} />
                            </label>
                        </div>

                        <div className="section-item">
                            <label>
                                Phone: {!verifyPhone() ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                                <InputMask mask="(999) 999-9999" slotChar="(___) ___-____" value={phone} onChange={(event) => setPhone(event.target.value)} />
                            </label>
                        </div>

                        <div className="section-item">
                            <label>
                                Gender: {gender ? <i class="fa-solid fa-check green-check"></i> : <i class="fa-thin fa-asterisk red-asterisk" />}
                                <Select
                                    styles={selectStyles}
                                    menuPortalTarget={document.body}
                                    menuPosition={'fixed'}
                                    value={gender}
                                    onChange={(option) => setGender(option)} options={GenderOptions} />
                            </label>
                        </div>
                    </div>
                </div>

                <div className="event-signin-form-section">
                    <div className="event-signin-form-section-list">
                        <div className="section-item">
                            <label for="streetAddress">
                                Street Address: {!verifyStreetAddress() ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                                <InputText id='streetAddress' value={streetAddress} onChange={(event) => setStreetAddress(event.target.value)} />
                            </label>
                        </div>

                        <div className="section-item">
                            <label for="streetAddress2">
                                Street Address 2:
                                <InputText id='streetAddress2' value={streetAddress2} onChange={(event) => setStreetAddress2(event.target.value)} />
                            </label>
                        </div>

                        <div className="section-item">
                            <label for="city">
                                City: {!verifyCity() ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                                <InputText id='city' value={city} onChange={(event) => setCity(event.target.value)} />
                            </label>
                        </div>

                        <div className="section-item">
                            <label for="zip">
                                ZIP: {!verifyZip() ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                                <InputText id='zip' value={zip} onChange={(event) => {
                                    if (verifyIsNumber(event.target.value)) {
                                        setZip(event.target.value);
                                    }
                                }} />
                            </label>
                        </div>

                        <div className="section-item">
                            <label>
                                State: {state ? <i class="fa-solid fa-check green-check"></i> : <i class="fa-thin fa-asterisk red-asterisk" />}
                                <Select
                                    styles={selectStyles}
                                    menuPortalTarget={document.body}
                                    menuPosition={'fixed'}
                                    value={state}
                                    onChange={(option) => setState(option)} options={StateOptions} />
                            </label>
                        </div>
                    </div>
                </div>

                <div className="event-signin-form-section">
                    <div className="section-item">
                        <label for="group-members">
                            How Many In Your Group?: {verifyGroupMembers() ? <i class="fa-solid fa-check green-check"></i> : <i class="fa-thin fa-asterisk red-asterisk" />}
                            <InputText id='group-members' value={groupMembers} onChange={(event) => setGroupMembers(event.target.value)} />
                        </label>
                    </div>
                </div>

                <h2 className="header bleeding-disorder-question">Is Anyone in Your Group Affected by a Bleeding Disorder?</h2>
                <div className="apply-buttons-container">
                    <button className={bleedingDisorder === false ? "apply-button declined no" : "apply-button no"}
                        onClick={() => {
                            setBleedingDisorder(false)
                        }}>No <i class="fa-solid fa-xmark"></i></button>

                    <button className={bleedingDisorder === true ? "apply-button approved yes" : "apply-button yes"}
                        onClick={() => { setBleedingDisorder(true) }}>Yes <i class="fa-solid fa-check"></i></button>
                </div>

                {renderBleedingDisorderQuestions()}

                <div className="event-signin-form-section">
                    <h2 className="header bleeding-disorder-question">Do you consent to your photo being used on our website and promotional materials?</h2>
                    <div className="apply-buttons-container">
                        <button className={photoOptIn === false ?
                            "apply-button declined no" : "apply-button no"}
                            onClick={() => {
                                setPhotoOptIn(false);
                            }}>No <i class="fa-solid fa-xmark"></i></button>

                        <button className={photoOptIn === true ?
                            "apply-button approved yes" : "apply-button yes"}
                            onClick={() => {
                                setPhotoOptIn(true);
                            }}>Yes <i class="fa-solid fa-check"></i></button>
                    </div>
                </div>

                <div className="event-signin-form-section">
                    <h2 className="header bleeding-disorder-question">Do you consent to sponsors contacting you using the information provided after the event?</h2>
                    <div className="apply-buttons-container">
                        <button className={sponsorContactOptIn === false ?
                            "apply-button declined no" : "apply-button no"}
                            onClick={() => {
                                setSponsorContactOptIn(false);
                            }}>No <i class="fa-solid fa-xmark"></i></button>

                        <button className={sponsorContactOptIn === true ?
                            "apply-button approved yes" : "apply-button yes"}
                            onClick={() => {
                                setSponsorContactOptIn(true);
                            }}>Yes <i class="fa-solid fa-check"></i></button>
                    </div>
                </div>
                {renderError()}
                {renderSubmitButton()}
            </div>
        </>
    )
}