import React from "react";

export default function ApplicationSubmitted(props) {
    const renderHowToMessage = () => {
        if (props.guest) {
            return (
                <>
                    <p>In the meantime, you can check the status of your application by using the
                        "Find Application" service built into our website either above or below this message.</p>
                    <p>In the future, we recommend making a user account as it makes the tracking process much easier!</p>
                    <form action="/find">
                            <button action="submit" className="approved-button">Find my Application</button>
                        </form>
                </>
            )

        } else {
            return (
                <>
                    <p>In the meantime, you can check the status of your application by going to your user dashboard
                        by clicking your name above and clicking 'Dashboard'. </p>
                    <p>Alternatively, you can click the button below
                        to be directed straight there.</p>
                        <form action="/dashboard">
                            <button action="submit" className="approved-button">My Dashboard</button>
                        </form>
                </>
            )

        }
    }

    return (
        <>
            <div className="application-submitted">
                <h1 className="header">Your Application Has Been Recieved!</h1>
                <h2 className="header">Thanks for your application {props.name}!</h2>
                <h2 className="header">Your Application Token is: {props.token}</h2>
                <p>We've sent you a confirmation email to your address {props.email}.</p>
                <p>Our staff has recieved the application and will review it as soon as possible.</p>
                {renderHowToMessage()}
            </div>
        </>
    )
}