import React, { useState, useLayoutEffect } from "react";
import axios from 'axios';
import "./AffiliateForm.css";
import Select from "react-select";
import { InputMask } from 'primereact/inputmask';
import { InputText } from 'primereact/inputtext';
import { AffiliateOptions } from "../Apply/DropdownOptions/AffiliateOptions";

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

export default function AffiliateForm(props) {
    const [width, height] = useWindowSize();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [affiliateType, setAffiliateType] = useState(null);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const handleSubmit = (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('name', name);
        formData.append('email', email);
        formData.append('type', affiliateType.value);
        console.log(formData);
        axios.post('/api/affiliates', formData, {
            withCredentials: true,
        }).then((res) => {
            if (res.data.status) {
                props.setSectionCompleted(true);
            } else {
                setError(true);
                setErrorMessage(res.data.message);
            }
        });
    }

    // Verifications

    const verifyName = () => {
        if (name.length < 2 || name.length > 50) {
            return false;
        }

        return true;
    }

    const verifyEmail = () => {
        if (email.includes('@') && email.includes('.') && email.length > 6 && email.length < 45) {
            return true;
        }
        return false;
    }

    const selectStyles = {
        option: (provided, state) => ({
            ...provided,
            fontWeight: state.isSelected ? "bold" : "normal",
            fontSize: width <= 1260 ? "1.2rem" : "1.4rem",
            color: "#2d3436",
            backgroundColor: "#fff",
            "&:hover": {
                backgroundColor: "#f0f0f0", // Change to your desired hover background color
            },
        }),
        control: (provided, state) => ({
            ...provided,
            borderColor: state.isFocused ? "#2d3436" : "#fff",
            margin: "12px 0 12px",
            height: width <= 1260 ? "48px" : "55px",
        }),
    };

    // Renders

    const renderSubmitButton = () => {
        if (verifyName() && verifyEmail() &&
            affiliateType !== null) {
            return (
                <button className="approved-button" onClick={handleSubmit}>Submit</button>
            );

        } else {
            return (
                <button className="denied-button" disabled>Submit</button>
            );
        }
    }

    return (
        <>
            <div className="affiliate-form">
                <p>
                    Starting in 2025, affiliates may register with us for easy access and updates regarding any referred applicants.
                </p>
                <p>This process is free and only requires providing us with your name or the name of your organization and an email for contact.</p>
                <p>Once you have submitted your application for being an affiliate of HUGS, we will get a notification right away and process your application.</p>
                <p>We look forward to working with you.</p>
                <h1 className="header">Affiliate Application</h1>

                <div className="affiliate-form-items">
                    <div>
                        <label for="fName">
                            Name: {!verifyName() ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                            <InputText id="fName" value={name} onChange={(event) => {
                                setName(event.target.value);
                            }} />
                        </label>
                    </div>

                    <div>
                        <label for="email">
                            Email: {!verifyEmail() ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                            <InputText id="email" value={email} onChange={(event) => {
                                setEmail(event.target.value);
                            }} />
                        </label>
                    </div>

                    <div>
                        <label>
                            Type of Affiliate: {affiliateType === null ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                            <Select
                                styles={selectStyles}
                                menuPortalTarget={document.body}
                                menuPosition={'fixed'}
                                value={affiliateType}
                                onChange={(option) => setAffiliateType(option)}
                                options={AffiliateOptions} />
                        </label>
                    </div>
                </div>

                {renderSubmitButton()}
            </div>
        </>
    )
}