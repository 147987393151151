import React, { useLayoutEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

// Import Custom Styles
import "./HomeStyles.css";

// Import Custom Components
import HomeHeader from '../components/Home/HomeHeader';
import HomeNewsletter from '../components/Home/HomeNewsletter';
import HomeEvents from '../components/Home/HomeEvents';
import SponsorsRow from "../components/Home/SponsorsRow";
import LoadingScreen from '../components/General/LoadingScreen';

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

export default function Home(props) {
    const userData = props.userData;
    const [width, height] = useWindowSize();

    // Protect Against Elements Loading Incorrectly due to Async
    if (width === 0) {
        return <LoadingScreen />
    }

    return (
        <>
            <div className="home">
                <Helmet>
                    <title>Homepage | Hearts Unite</title>
                    {/* Limits User Zoom */}
                    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
                    <meta name="description" content="Hearts Unite to Give Support (HUGS) is a nonprofit organization dedicated to helping individuals and families during difficult times. Through advocacy, education, and financial support, we aim to reduce stress and provide much-needed assistance to those in need." />
                    <meta property="og:title" content="Homepage | Hearts Unite" />
                    <meta property="og:image" content="/images/hugs-logo.png" />
                </Helmet>

                <div className="home-content">

                    <HomeHeader />

                    <SponsorsRow />

                    {/* Removing Tutorial for now because I can't see a good reason for it */}
                    {/* <HomeTutorial /> */}

                    <HomeNewsletter />

                    <HomeEvents />

                    <div className='get-involved-section'>
                        <h1 className='header'>Get Involved</h1>
                        <div className='involved-section-container'>
                            <div className='involved-buttons'>
                                <form action='/about'>
                                    <button action='submit' className={'involvement-button, approved-button'}>
                                        Learn More
                                    </button>
                                </form>
                                <form action='join'>
                                    <button className={'involvement-button, approved-button'}>
                                        Join Our Team
                                    </button>
                                </form>

                                <form action='donate'>
                                    <button className={'involvement-button, approved-button'}>
                                        Donate
                                    </button>
                                </form>

                                <form action='contact'>
                                    <button className={'involvement-button, approved-button'}>
                                        Contact Us
                                    </button>
                                </form>
                            </div>

                            <div className='involved-image-container'>
                                <img className="involved-image" alt="hugs members standing" src="/images/people-standing-clear.png" />
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </>

    )
}