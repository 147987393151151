import React, { useLayoutEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet';

// Custom Components
import LoginForm from "../components/Login/LoginForm";
import VerificationForm from '../components/Login/VerificationForm';

// React-reveal for animations

import Fade from 'react-reveal/Fade';

// Custom Styling

import "./LoginStyles.css";

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

export default function Login(props) {
    const userData = props.userData;
    const [width, height] = useWindowSize();
    let navigate = useNavigate();

    // Renders

    const renderLoginForm = () => {
        if (userData) {
            if (!userData.verified) {
                return <VerificationForm />
            } else {
                return navigate("/dashboard");
            }

        } else {
            return (
                <>
                    <Fade delay={150}>
                        <div className="login-container">
                            <LoginForm />
                        </div>
                    </Fade>
                </>
            )
        }

    }

    const renderLoginImage = () => {
        if (width > 925) {
            return (
                <div className='login-image'>
                    <Fade>
                        <img className="hugs-logo-login" src="/images/hugs-logo.png" />
                    </Fade>
                </div>
            )
        }
    }

    return (
        <>
            <div className="login">
                <Helmet>
                    <title>Login | Hearts Unite</title>
                    {/* Limits User Zoom */}
                    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
                    <meta name="description" content="Login to your Hearts Unite to Give Support dashboard for tracking and reviewing applications for financial assistance." />
                    <meta property="og:title" content="Login | Hearts Unite" />
                    <meta property="og:image" content="/images/hugs-logo.png" />
                </Helmet>
                <Fade>
                    <div className='page-header'>
                        <h1 className='header'>Bridge The Gap</h1>
                    </div>
                </Fade>


                <div className='login-flexbox'>

                    {renderLoginImage()}


                    {renderLoginForm()}
                </div>

            </div>

        </>


    )
}