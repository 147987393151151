import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import MeetTheTeam from '../components/About/MeetTheTeam';
import AboutUs from '../components/About/AboutUs';
import Sponsorships from '../components/About/Sponsorships';

import { Fade } from "react-reveal";

import "./AboutStyles.css";

const about = 0;
const team = 1;
const sponsorships = 2;

export default function About(props) {
    const userData = props.userData;
    const [selected, setSelected] = useState(about);
    const [headerText, setHeaderText] = useState("About Us");

    const renderAboutNav = () => {
        return (
            <>
                <div className='about-nav'>
                    <button className={selected === about ? "selected-about-button" : "approved-button"}
                        onClick={() => {
                            setSelected(about);
                            setHeaderText("About Us");
                        }}>
                        About Us
                    </button>

                    {/* <button className={selected === sponsorships ? "selected-about-button" : "approved-button"}
                        onClick={() => {
                            setSelected(sponsorships);
                            setHeaderText("Sponsorships");
                        }}>
                        Sponsorships
                    </button> */}

                    <button className={selected === team ? "selected-about-button" : "approved-button"}
                        onClick={() => {
                            setSelected(team);
                            setHeaderText("Meet The Team");
                        }}>
                        Meet The Team
                    </button>
                </div>
            </>
        )
    }

    const renderSelection = () => {
        switch (selected) {
            case about:
                return <AboutUs />;

            case sponsorships:
                return <Sponsorships />;

            case team:
                return <MeetTheTeam />;
        }
    }

    return (
        <div className='about'>
            <Helmet>
                <title>{headerText} | Hearts Unite</title>
                {/* Limits User Zoom */}
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
                <meta name="description" content="Learn about the mission of Hearts Unite to Give Support and meet the team members." />
                <meta property="og:title" content="About Us | Hearts Unite" />
                <meta property="og:image" content="/images/hugs-logo.png" />
            </Helmet>

            <Fade>
                <div className='page-header'>
                    <h1 className='header'>{headerText}</h1>
                </div>
            </Fade>

            {renderAboutNav()}
            {renderSelection()}
        </div>
    )
}