import React, { useEffect, useState, useLayoutEffect } from "react";
import axios from 'axios';
import { Helmet } from 'react-helmet';

import Select from 'react-select';
import Fade from 'react-reveal/Fade';

import "./EventSignIn.css";
import SelectedEventControl from "../components/EventSignIn/SelectedEventControl";

const eventAccessKey = 'Event Access';

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

export default function EventSignin(props) {
    const [width, height] = useWindowSize();
    const userData = props.userData;
    const [eventsLoading, setEventsLoading] = useState(true);
    const [activeEvents, setActiveEvents] = useState(null);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [selectedEventOption, setSelectedEventOption] = useState(null);
    const [eventOptions, setEventOptions] = useState(null);

    const selectStyles = {
        option: (provided, state) => ({
            ...provided,
            fontWeight: state.isSelected ? "bold" : "normal",
            fontSize: width <= 1260 ? "1.2rem" : "1.4rem",
            color: "#2d3436",
            backgroundColor: "#fff",
            "&:hover": {
                backgroundColor: "#f0f0f0",
            },
        }),
        control: (provided, state) => ({
            ...provided,
            borderColor: state.isFocused ? "#2d3436" : "#fff",
            margin: "12px 0 12px",
            height: width <= 1260 ? "48px" : "55px",
            backgroundColor: "#fff",
            "&:hover": {
                backgroundColor: "#f0f0f0",
            },
            width: '80%',
            margin: '0 auto'
        }),
    };

    // Send non-employees to login
    if (!userData || userData === null || !userData.employee) {
        window.location.href = '/login';
    };

    useEffect(() => {
        axios.defaults.withCredentials = true;

        // Events
        if (props.userData.clientSidePermissions.includes(eventAccessKey)) {
            if (!activeEvents) {
                setEventsLoading(true);
                axios.get('/api/upcoming-events-for-signin', { withCredentials: true })
                    .then((res) => {
                        setActiveEvents(res.data.events);
                        var localEventOptions = [];
                        for (var i = 0; i < res.data.events.length; i++) {
                            localEventOptions.push({ value: res.data.events[i].name, label: res.data.events[i].name });
                        }
                        setEventOptions(localEventOptions);
                        setEventsLoading(false);
                    })
                    .catch((err) => {
                        setEventsLoading(false);
                    })
            }
        } else {
            setEventsLoading(false);
        }
    }, [props.userData.clientSidePermissions])

    // Functions

    const resetComponent = () => {
        setSelectedEventOption(null);
        setSelectedEvent(null);
    }

    const updateSelectedGuestList = (newGuestListEntry) => {
        if (selectedEvent) {
            setSelectedEvent(prevEvent => ({
                ...prevEvent,
                attendingList: [...prevEvent.signinData, newGuestListEntry],
            }));
        }
    }

    // Renders

    const renderEventSelector = () => {
        if (eventsLoading) return null;

        if (!selectedEvent) {
            return (
                <>
                    <Fade>
                        <div className="event-signin-select">
                            <div className="event-signin-selector-container">
                                <label>Select Event</label>
                                <Select
                                    styles={selectStyles}
                                    menuPortalTarget={document.body}
                                    menuPosition={'fixed'}
                                    value={selectedEventOption}
                                    onChange={(option) => {
                                        // Update option state if not done
                                        if (selectedEventOption === null || selectedEventOption.value !== option.value) {
                                            setSelectedEventOption(option);
                                        }

                                        // Find and update event object if not updated
                                        const matchingEvent = activeEvents.find(event => event.name === option.value);
                                        if (matchingEvent && matchingEvent !== selectedEvent) {
                                            setSelectedEvent(matchingEvent);
                                        }
                                    }} options={eventOptions} />
                            </div>
                        </div>
                    </Fade>
                </>
            )
        }

        return (
            <>
                <Fade>
                    <SelectedEventControl
                        selectedEvent={selectedEvent}
                        goBack={resetComponent}
                        width={width}
                        height={height}
                        updateSelectedGuestList={updateSelectedGuestList}
                    />
                </Fade>
            </>
        )
    }


    if (userData.employee) {
        return (
            <>
                <Helmet>
                    <title>Event Sign In | Hearts Unite</title>
                    {/* Limits User Zoom */}
                    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
                    <meta name="description" content="Sign in to your Hearts Unite to Give Support event through this page to show that you attended our event." />
                    <meta property="og:title" content="Event Sign-in | Hearts Unite" />
                    <meta property="og:image" content="/images/hugs-logo.png" />
                </Helmet>
                <div className="page-header">
                    <h1 className="header header-container">{eventsLoading ? "Loading Events..." : "Event Sign In"}</h1>
                </div>
                <div className="event-signin">
                    {renderEventSelector()}
                </div>
            </>
        )
    }

}