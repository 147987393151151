import React, { useState } from "react";

import EventQuestion from "./EventQuestion";

// For use in EventAdd and EventEdit
// takes questions, updateQuestions, and width as inputs
export default function EventQuestionsContainer(props) {
    const [localQuestions, setLocalQuestions] = useState(props.questions);

    // local functions
    const addQuestion = () => {
        const newQuestion = {
            question: "",
            type: "",
        }
        setLocalQuestions(prevQuestions => [...prevQuestions, newQuestion]);
        props.updateQuestions(prevQuestions => [...prevQuestions, newQuestion]);
    }

    const updateQuestionAtIndex = (index, question) => {
        const newQuestions = [...localQuestions];
        newQuestions[index].question = question;
        setLocalQuestions(newQuestions);
        props.updateQuestions(newQuestions);
    }

    const updateQuestionTypeAtIndex = (index, option) => {
        const newQuestions = [...localQuestions];
        newQuestions[index].type = option;
        setLocalQuestions(newQuestions);
        props.updateQuestions(newQuestions);
    }

    const removeQuestion = (index) => {
        setLocalQuestions(prevQuestions =>
            prevQuestions.filter((_, i) => i !== index));

        props.updateQuestions(prevQuestions =>
            prevQuestions.filter((_, i) => i !== index));
    }

    const renderAddQuestionButton = () => {
        if (localQuestions.length < 5) {
            return (
                <>
                    <button className="approved-button" onClick={() => {
                        addQuestion();
                    }}>Add Question</button>
                </>
            )
        } else {
            return (
                <>
                    <button className="denied-button">Maximum Questions Reached</button>
                </>
            )
        }
    }

    return (
        <>
            <div className="event-instructions">
                <div className="event-question-text-container">
                    <h1 className="header">Event Questions</h1>
                    <p>Add up to 5 questions for users to respond to, categorized as yes or no questions or text questions.</p>
                    <p>For best results please limit instructions to 1 sentence, for example: </p>
                    <p>"Please list children attending."</p>
                </div>
                {localQuestions.map((question, index) => {
                    return (
                        <EventQuestion index={index} question={question}
                        updateQuestion={updateQuestionAtIndex} updateQuestionType={updateQuestionTypeAtIndex}
                        removeQuestion={removeQuestion} width={props.width}/>
                    )
                })}
                <div className="add-question-button-container">
                    {renderAddQuestionButton()}
                </div>
            </div>

        </>
    )
}