export const DirectorsData = [
    {
        name: "Hilda Pretty",
        title: "Registered Nurse",
        img: "/images/hilda-pretty.jpg",
        alt: "picture of hilda pretty",
    },
    {
        name: "Lisa Wiles",
        title: "Rare Disease Specailist",
        img: "/images/lisa-wiles.jpg",
        alt: "picture of lisa wiles",
    },
    {
        name: "Joseph Perkins",
        title: "Board Member",
        img: "/images/joeseph-perkins.jpg",
        alt: "picture of joeseph perkins",
    },
    {
        name: "Rhonda Kuszak",
        title: "Legal Office Assistant",
        img: "/images/rhonda-kuszak.jpg",
        alt: "picture of rhonda kuszak",
    },
]