import React from "react";
import './AffiliatePreview.css';

export default function AffiliatePreview(props) {
    const name = props.name;
    const email = props.email;
    const type = props.type;
    const associatedApplications = associatedApplications;

    return (
        <>
            <div className="affiliate-preview-container">
                <div>
                    <h2>Name: </h2>
                    <p>{name}</p>
                </div>

                <div>
                    <h2>Email: </h2>
                    <p>{email}</p>
                </div>

                <div>
                    <h2>Type of Organisation: </h2>
                    <p>{type}</p>
                </div>
            </div>
        </>
    )
};