import React, { useState } from 'react';
import BoardMemberForm from './BoardMemberForm';
import AmbassadorForm from './AmbassadorForm';
import AffiliateForm from './AffiliateForm';

import { Fade } from 'react-reveal';

const nothing = 0;
const board = 1;
const ambassador = 2;
const affilliate = 3;

export default function SectionControl(props) {
    const type = props.type;
    const [completed, setCompleted] = useState(false);

    const renderSelectedSection = () => {
        switch (props.sectionRendering) {
            case nothing:
                return (
                    <></>
                )

            case board:
                return (
                    <>
                        <Fade>
                            <BoardMemberForm userData={props.userData} setSectionComplete={setCompleted}/>
                        </Fade>
                    </>
                )

            case ambassador: {
                return (
                    <>
                        <Fade>
                            <AmbassadorForm userData={props.userData} setSectionComplete={setCompleted}/>
                        </Fade>
                    </>
                )
            }

            case affilliate: {
                return (
                    <>
                        <Fade>
                            <AffiliateForm userData={props.userData} setSectionComplete={setCompleted}/>
                        </Fade>
                    </>
                )
            }
        }
    }

    if (completed) {
        return (
            <>
                <h1 className='header'>Application Submitted</h1>
                <p>Thanks for your application! We have recieved it and our staff have been notified.</p>
                <p>Please wait patiently while we review the application and get back to you. If you have had any issues or concerns, please use the report function to let us know.</p>
                <p>For more urgent businesss matters, use the information located on the contact page.</p>
                <p>Thank you!</p>
            </>
        )
    }


    return (
        <>
            {renderSelectedSection()}
        </>
    )


}