import React from "react";

// For use where nav / controls are implemented and no selection has been made
// Remember to wrap in 'display-container' div if needed
export default function NoSelection() {
    return (
    <>
                <h1 className="header">Nothing Selected</h1>
                <p>Press a button above to get started.</p>
    </>
    )
}