import React, { useState, useLayoutEffect } from 'react';
import axios from 'axios';

// Custom Components
import AffiliatePreview from './AffiliatePreview';
import NoSelection from '../../GeneralDisplays/NoSelection';

import { Fade } from 'react-reveal';

import './AffiliateControl.css';

axios.defaults.withCredentials = true;
const nothing = 0;
const pending = 1;
const approved = 2;
const all = 3;
const search = 4;

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

export default function AffiliateControl(props) {
    const [width, height] = useWindowSize();
    const [affiliates, setAffiliates] = useState(props.affiliates);
    const [showing, setShowing] = useState(nothing);
    const [opened, setOpened] = useState("");
    const [activeAffiliate, setActiveAffiliate] = useState("");
    const [editing, setEditing] = useState(false);

    const onPendingClick = () => {
        if (showing !== pending) {
            setOpened("");
            setShowing(pending);
        } else {
            setOpened("");
            setShowing(nothing);
        }
    }

    const onApprovedClick = () => {
        if (showing !== approved) {
            setOpened("");
            setShowing(approved);
        } else {
            setOpened("");
            setShowing(nothing);
        }
    }

    const onAllClick = () => {
        if (showing !== all) {
            setOpened("");
            setShowing(all);
        } else {
            setOpened("");
            setShowing(nothing);
        }
    }

    const onSearchClick = () => {
        if (showing !== search) {
            setOpened("");
            setShowing(search);
        } else {
            setOpened("");
            setShowing(nothing);
        }
    }

    // Update Affiliates
    const updateAffiliates = (newAffiliate) => {
        // Update Parent State
        props.setAffiliates((prevAffiliates) =>
            prevAffiliates.map((affiliate) =>
                affiliate.affiliateID === affiliate.affiliateID ? newAffiliate : affiliate
            )
        );

        // Update Local State
        setAffiliates((prevAffiliates) =>
            prevAffiliates.map((affiliate) =>
                affiliate.affiliateID === newAffiliate.ID ? newAffiliate : affiliate
            )
        );
    };

    const onAffiliateClick = (id) => {
        setOpened(id);
    }

    const onViewFullClick = (affiliate) => {
        setOpened("");
        setActiveAffiliate(affiliate);
        setShowing(nothing);
        setEditing(true);
    }

    // Renders

    const renderAffiliateItem = (item) => {
        if (opened === item.affiliateID) {
            return (
                <li className="application-container"
                    key={item.affiliateID}>
                    <button className={item.approved ? "application approved" :
                        "application pending"}
                        onClick={() => onAffiliateClick('')}>
                        <i class="fa-solid fa-angle-down" />
                        {width > 880 ?
                            " " + item.name + " " + item.email + " - " + item.type + " | Submitted on - " + item.dateAdded
                            : " " + item.name + " " + item.email + " - " + item.type}
                    </button>
                    <AffiliatePreview name={item.name} email={item.email}
                        type={item.type} associatedApplications={item.associatedApplications} />
                    <div>
                        <button className='view-full-button approved-button'
                            onClick={() => onViewFullClick(item)}
                        >View Full Details</button>
                    </div>

                </li>
            )
        } else {
            return (
                <li className='application-container' key={item.affiliateID}>
                    <button className={item.approved ? "application approved" :
                        "application pending"}
                        onClick={() => onAffiliateClick(item.affiliateID)}>
                        <i class="fa-solid fa-angle-right" />
                        {width > 880 ?
                            " " + item.name + " " + item.email + " - " + item.type + " | Submitted on - " + item.dateAdded
                            : " " + item.name + " " + item.email + " - " + item.type}
                    </button>
                </li>
            )
        }
    }

    const renderSelection = () => {
        switch (showing) {
            case (pending):
                return (
                    <>
                        <ul className='applications-list'>
                            <div className='applications-list-padding'>
                                {affiliates.map((item, index) => {
                                    if (!item.approved) {
                                        return (
                                            <Fade>
                                                {renderAffiliateItem(item)}
                                            </Fade>
                                        )
                                    }
                                })}
                            </div>
                        </ul>
                    </>
                )

            case (approved): {
                return (
                    <ul className='applications-list'>
                        <div className='applications-list-padding'>
                            {affiliates.map((item, index) => {
                                if (item.approved) {
                                    return (
                                        <Fade>
                                            {renderAffiliateItem(item)}
                                        </Fade>
                                    )
                                }
                            })}
                        </div>
                    </ul>
                )
            }

            case (all): {
                return (
                    <ul className='applications-list'>
                        <div className='applications-list-padding'>
                            {affiliates.map((item, index) => {
                                return (
                                    <Fade>
                                        {renderAffiliateItem(item)}
                                    </Fade>
                                )
                            })}
                        </div>
                    </ul>
                )
            }

            default: {
                return (
                    <NoSelection />
                )
            }

        }
    }

    if (editing) {
        if (activeAffiliate) {
            
        }
    }

    return (
        <>
            <div className='display-container'>
                <h1 className='header'>Affiliates</h1>
                <div className='affiliate-control'>
                    <div className='affiliate-control-top-row'>
                        <button className='pending-button affiliate-control-button'
                            onClick={onPendingClick}>
                            View Pending
                        </button>

                        <button className='approved-button affiliate-control-button'
                            onClick={onApprovedClick}>
                            View Approved
                        </button>

                        <button className='all-button affiliate-control-button'
                            onClick={onAllClick}>
                            View All
                        </button>
                    </div>

                    <div className='affiliate-control-search-button-container'
                        onClick={onSearchClick}>
                        <button className='search-button'>
                            Search
                        </button>
                    </div>
                </div>

                {renderSelection()}
            </div>
        </>
    )
}