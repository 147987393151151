import React, { useState } from "react";
import Select from 'react-select';
import { InputTextarea } from "primereact/inputtextarea";

// Local variables
const questionOptions = [
    { value: textQuestion, label: "Text Question" },
    { value: trueFalse, label: "Yes or No Question" }
];

const textQuestionOption = {
    value: textQuestion, label: "Text Question"
};

const trueFalseQuestionOption = {
    value: trueFalse, label: "Yes or No Question"
};

const trueFalse = 0;
const textQuestion = 1;

// For use with EventQuestionsContainer component
// Takes width, index, question, updateQuestion, updateQuestionType, 
// & removeQuestion as inputs
export default function EventQuestion(props) {
    const index = props.index;
    const question = props.question;
    const [localQuestionType, setLocalQuestionType] = useState(question.type === "Text Question" ? textQuestionOption : trueFalseQuestionOption);

    const selectStyles = {
        option: (provided, state) => ({
            ...provided,
            fontWeight: state.isSelected ? "bold" : "normal",
            fontSize: props.width <= 1260 ? "1.2rem" : "1.4rem",
            color: "#2d3436",
            backgroundColor: "#fff",
            "&:hover": {
                backgroundColor: "#f0f0f0", // Change to your desired hover background color
            },
        }),
        control: (provided, state) => ({
            ...provided,
            borderColor: state.isFocused ? "#2d3436" : "#fff",
            margin: "12px 0 12px",
            height: props.width <= 1260 ? "48px" : "55px",
        }),
    };

    return (
        <>
            <div className="event-question-container">
                <label className="question-header-label">
                    <h2>Question {index + 1}:</h2>
                </label>
                <div className="question-row">
                    <div className="question-content-container">
                        <label className="question-label">
                            Question Content: {question.question ? <i class="fa-solid fa-check green-check"></i> : <i class="fa-thin fa-asterisk red-asterisk" />}
                        </label>
                        <InputTextarea value={question.question} onChange={(event) => {
                            props.updateQuestion(index, event.target.value);
                        }} />
                    </div>

                    <div className="question-type-container">
                        <label>
                            Question Type: {question.type ? <i class="fa-solid fa-check green-check"></i> : <i class="fa-thin fa-asterisk red-asterisk" />}
                            <Select
                                styles={selectStyles}
                                menuPortalTarget={document.body}
                                menuPosition={'fixed'}
                                value={localQuestionType}
                                onChange={(option) => {
                                    props.updateQuestionType(index, option);
                                    setLocalQuestionType(option);
                                }} options={questionOptions} />
                        </label>
                    </div>

                    <div className="question-remove-container">
                        <button className="denied-button" onClick={() => { props.removeQuestion(index) }}>Remove</button>
                    </div>
                </div>
            </div>
        </>
    )
}