import React, { useState } from 'react';
import './EmployeeNavbar.css';

import { Fade } from 'react-reveal';

export default function EmployeeNavbar(props) {
    const [applicationsSelected, setApplicationsSelected] = useState(false);


    const renderApplicationButton = () => {
        if (applicationsSelected) {
            return (
                <>

                    <div className='employee-dashboard-nav-application-dropdown'>
                        <button className={props.applications > 0 ? "pending-button" : "approved-button"}
                            onClick={() => {
                                setApplicationsSelected(!applicationsSelected);
                            }}>
                            <i class={applicationsSelected ? "fa-solid fa-angle-down" : "fa-solid fa-angle-right"} /> Applications ({props.applications})</button>
                        <Fade delay={100}>
                            <button className={props.applications > 0 ? "pending-button employee-dashboard-nav-application-dropdown-child" : "approved-button employee-dashboard-nav-application-dropdown-child"}
                                onClick={() => {
                                    props.updateDisplay(props.showApplications);
                                }}>
                                Assistance ({props.applications})</button>
                        </Fade>

                        <Fade delay={200}>
                            <button className={props.applications > 0 ? "pending-button employee-dashboard-nav-application-dropdown-child" : "approved-button employee-dashboard-nav-application-dropdown-child"}
                                onClick={() => {
                                    props.updateDisplay(props.showAffiliateApplications);
                                }}>
                                Affiliate</button>
                        </Fade>

                        <Fade delay={300}>
                            <button className={props.applications > 0 ? "pending-button employee-dashboard-nav-application-dropdown-child" : "approved-button employee-dashboard-nav-application-dropdown-child"}
                                onClick={() => {
                                    props.updateDisplay(props.showEmployeeApplications);
                                }}>
                                Employee</button>
                        </Fade>
                    </div>
                </>
            )
        }

        return (
            <div>
                <button className={props.applications > 0 ? "pending-button" : "approved-button"}
                    onClick={() => {
                        setApplicationsSelected(!applicationsSelected);
                    }}><i class={applicationsSelected ? "fa-solid fa-angle-down" : "fa-solid fa-angle-right"} /> Applications ({props.applications})</button>
            </div>
        )
    }



    return (
        <>

            <div className='employee-navbar-items'>
                {renderApplicationButton()}

                <div>
                    <button className={props.events > 0 ? "pending-button" : "approved-button"}
                        onClick={() => {
                            props.updateDisplay(props.showEvents);
                        }}>
                        Events ({props.events})</button>
                </div>

                <div>
                    <button className={props.reports > 0 ? "pending-button" : "approved-button"}
                        onClick={() => {
                            props.updateDisplay(props.showReports);
                        }}>
                        Reports ({props.reports})</button>
                </div>

                <div>
                    <button className='approved-button'
                        onClick={() => {
                            props.updateDisplay(props.showSettings);
                        }}>
                        Settings</button>
                </div>

            </div>
        </>
    )
}