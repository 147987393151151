import React, { Component } from "react";

export default function ApplicationLoginPrompt() {
    return (
        <>
            <div className="prompt">
                <h1 className="header">Before you Continue</h1>
                <p>In order to request assistance, a user account is required.</p>
                <p>Making an account is quick, easy, and free.</p>
                <p>With an account you will be able to track the status of your application in real time, and it makes it easier for us to communicate with you when you need help.</p>
                <p>If you wish to proceed, please click below to get started.</p>
                <ul className="prompt-buttons">
                    <li>
                        <form action="/login">
                            <button action="submit" className='approved-button'>Create an Account</button>
                        </form>
                    </li>
                </ul>
            </div>
        </>
    )
}
