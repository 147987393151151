import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import JoinLoginPrompt from '../components/Join/JoinLoginPrompt';
import SectionControl from '../components/Join/SectionControl';


// React Reveal for Animations
import { Fade } from 'react-reveal';

// Custom Styles
import "./JoinStyles.css";

const nothing = 0;
const board = 1;
const ambassador = 2;
const affilliate = 3;

function Join(props) {
    const userData = props.userData;

    const [showing, setShowing] = useState(nothing);

    // Renders

    const renderJoinPrompt = () => {
        if (userData) {
            return (
                <>
                    <div className='join-section'>
                        <label>
                            First, how would you like to join?
                        </label>

                        <div className="join-nav">
                            <button className={showing === affilliate ? "apply-button approved yes" : "apply-button yes"}
                                onClick={() => {
                                    if (showing !== affilliate) {
                                        setShowing(affilliate);
                                    } else {
                                        setShowing(nothing);
                                    }
                                }}>Affiliate</button>

                            <button className={showing === ambassador ? "apply-button approved yes" : "apply-button yes"}
                                onClick={() => {
                                    if (showing !== ambassador) {
                                        setShowing(ambassador);
                                    } else {
                                        setShowing(nothing);
                                    }
                                }}>Ambassador</button>

                            <button className={showing === board ? "apply-button approved yes" : "apply-button yes"}
                                onClick={() => {
                                    if (showing !== board) {
                                        setShowing(board);
                                    } else {
                                        setShowing(nothing);
                                    }
                                }}>Board Member</button>
                        </div>
                    </div>

                </>
            )
        } else {
            return (
                <JoinLoginPrompt />
            )

        }
    }

    return (
        <>
            <div className='join'>
                <Helmet>
                    <title>Join The Team | Hearts Unite</title>
                    {/* Limits User Zoom */}
                    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
                    <meta name="description" content="Apply to join the Hearts Unite to Give Support team as a board member." />
                    <meta property="og:title" content="Join The Team | Hearts Unite" />
                    <meta property="og:image" content="/images/hugs-logo.png" />
                </Helmet>
                <Fade>
                    <div className='page-header'>
                        <h1 className='header'>Join the Team</h1>
                    </div>
                </Fade>

                <Fade>
                    {renderJoinPrompt()}
                    <SectionControl sectionRendering={showing} userData={userData} />
                </Fade>
            </div>

        </>
    )
}

export default Join;