import React, { useState } from 'react';


export default function FullApplicationView(props) {
    const application = props.application;

    return (
        <>
            <div>
                <div className='back-button'>
                    <button className='denied-button' onClick={props.changeShowingToDashboard}><i class="fa-solid fa-left-long"></i> Go Back</button>
                </div>
                <div className='container-header'>
                    <h1 className='header'>All Details</h1>
                </div>

                <div className="user-review-container">
                    <div className="review-section">
                        <h2 className='header'>General</h2>
                        <div className="review-items">
                            <div className="review-item">
                                <h3>First Name: </h3> <p>{application.firstName ? application.firstName : "N/A"}</p>
                            </div>

                            <div className="review-item">
                                <h3>Last Name: </h3> <p>{application.lastName ? application.lastName : "N/A"}</p>
                            </div>

                            <div className="review-item">
                                <h3>Email: </h3> <p>{application.email ? application.email : "N/A"}</p>
                            </div>

                            <div className="review-item">
                                <h3>Birthday: </h3> <p>{application.birthday ? application.birthday : "N/A"}</p>
                            </div>

                            <div className="review-item">
                                <h3>Phone: </h3> <p>{application.phone ? application.phone : "N/A"}</p>
                            </div>

                            <div className="review-item">
                                <h3>Income: </h3> <p>{"$" + application.income + "/mo" ? 
                                "$" + application.income + "/mo" : "N/A"}</p>
                            </div>

                            <div className="review-item">
                                <h3>Gender: </h3> <p>{application.gender ? application.gender : "N/A"}</p>
                            </div>

                            <div className="review-item">
                                <h3>Relationship Status: </h3> <p>{application.relationshipStatus ? application.relationshipStatus : "N/A"}</p>
                            </div>

                        </div>


                    </div>

                    <div className="review-section">
                        <h2 className='header'>Address Information</h2>

                        <div className="review-items">
                            <div className="review-item">
                                <h3>Street Address: </h3> <p>{application.streetAddress ? application.streetAddress : "N/A"}</p>
                            </div>

                            <div className="review-item">
                                <h3>Street Address 2: </h3> <p>{application.streetAddress2 ? application.streetAddress2 : "N/A"}</p>
                            </div>

                            <div className="review-item">
                                <h3>City: </h3> <p>{application.city ? application.city : "N/A"}</p>
                            </div>

                            <div className="review-item">
                                <h3>State: </h3> <p>{application.state ? application.state : "N/A"}</p>
                            </div>

                            <div className="review-item">
                                <h3>ZIP: </h3> <p>{application.zip ? application.zip : "N/A"}</p>
                            </div>
                        </div>

                    </div>

                    <div className="review-section">
                        <h2 className='header'>Medical Information</h2>

                        <div className="review-items">
                            <div className="review-item">
                                <h3>Bleeding Disorder: </h3> <p>{application.bleedingDisorder ? "Yes" : "No"}</p>
                            </div>

                            <div className="review-item">
                                <h3>Relationship to Bleeding Disorder: </h3> <p>{application.bleedingDisorder ? application.bleedingDisorderRelationship : "N/A"}</p>
                            </div>

                            <div className="review-item">
                                <h3>Type: </h3> <p>{application.bleedingDisorder ? application.bleedingDisorderType : "N/A"}</p>
                            </div>

                            <div className="review-item">
                                <h3>Severity: </h3> <p>{application.bleedingDisorder ? application.bleedingDisorderSeverity : "N/A"}</p>
                            </div>

                            <div className="review-item">
                                <h3>HTC: </h3> <p>{application.bleedingDisorder ? application.HTC : "N/A"}</p>
                            </div>
                        </div>
                    </div>

                    <div className="review-section">
                        <h2 className='header'>Request Information</h2>

                        <div className="review-items">
                            <div className="review-item">
                                <h3>Type of Assistance: </h3> <p>{application.type ? application.type : "N/A"}</p>
                            </div>

                            <div className="review-item">
                                <h3>Method of Assistance: </h3> <p>{application.method ? application.method : "N/A"}</p>
                            </div>

                            <div className="review-item">
                                <h3>Amount Requested (USD): </h3> <p>{application.amount ? application.amount : "N/A"}</p>
                            </div>
                        </div>

                        <h3>Reason for Applying:</h3>
                        <p>{application.details}</p>
                    </div>

                    <div className="review-section">
                        <h2 className='header'>Referral Information</h2>

                        <div className="review-items">
                            <div className="review-item">
                                <h3>Referred: </h3> <p>{application.referred ? "Yes" : "No"}</p>
                            </div>

                            <div className="review-item">
                                <h3>Who Referred You?: </h3> <p>{application.referred ? application.referrer : "N/A"}</p>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}