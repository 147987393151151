import React, { useState } from 'react';

import { Fade } from 'react-reveal';

function UserApplicationsContainer(props) {
    // Width
    const width = props.width;

    const applications = props.applications;
    const [opened, setOpened] = useState("");

    const onApplicationClick = (token) => {
        setOpened(token);
    }

    const renderStatus = (status) => {
        if (width <= 480) {
            return (
                <>
                    <div className='application-info-item'>
                        <h2>Status: </h2> <p>{status}</p>
                    </div>
                </>)

        }
    }

    // Changes the value of 'opened' to the current clicked item, revealing all information about the application
    const renderApplicationItem = (item) => {
        if (opened === item.token) {
            return (
                <li className="application-container"
                    key={item.token}>
                    <button className={item.status === "Approved" ? "application approved" :
                        item.status === "Pending" ? "application pending" :
                            item.status === "Denied" ? "application denied" : "application"}
                        onClick={() => onApplicationClick('')}>
                        <i class="fa-solid fa-angle-down" />{width > 880 ?
                            " Application " + item.token + "| Submitted on - " + item.issuedOn + " | Status - " + item.status
                            : "Application " + item.token + " | Status - " + item.status}
                    </button>
                    <div className='application-info-list'>
                        <div className='application-info-item'>
                            <h2>First Name: </h2> <p>{item.firstName}</p>
                        </div>

                        <div className='application-info-item'>
                            <h2>Last Name: </h2> <p>{item.lastName}</p>
                        </div>

                        <div className='application-info-item'>
                            <h2>Gender: </h2> <p>{item.gender}</p>
                        </div>

                        <div className='application-info-item'>
                            <h2>Birthday: </h2> <p>{item.birthday}</p>
                        </div>

                        <div className='application-info-item'>
                            <h2>Email: </h2> <p>{item.email}</p>
                        </div>

                        <div className='application-info-item'>
                            <h2>Phone: </h2> <p>{item.phone}</p>
                        </div>

                        <div className='application-info-item'>
                            <h2>Type of Assistance: </h2> <p>{item.type}</p>
                        </div>

                        <div className='application-info-item'>
                            <h2>Assistance Method: </h2> <p>{item.method}</p>
                        </div>

                        <div className='application-info-item'>
                            <h2>Assistance Amount: </h2> <p>${item.amount}</p>
                        </div>

                        {renderStatus(item.status)}

                    </div>

                    <div className='view-full-application-button'>
                        <button className='approved-button' onClick={() => props.changeShowingToApplication(item)}>View Full Application</button>
                    </div>

                </li>
            )
        } else {
            return (
                <li className='application-container' key={item.token}>
                    <button className={item.status === "Approved" ? "application approved" :
                        item.status === "Pending" ? "application pending" :
                            item.status === "Denied" ? "application denied" : "application"}
                        onClick={() => onApplicationClick(item.token)}>
                        <i class="fa-solid fa-angle-right" />{width > 880 ?
                            " Application " + item.token + "| Submitted on - " + item.issuedOn + " | Status - " + item.status
                            : "Application " + item.token + " | Status - " + item.status} </button>
                </li>
            )

        }
    }

    const renderApplications = () => {
        if (applications) {
            return (
                <ul className="applications-list">
                    {applications.map((item, index) => {
                        return (
                            <Fade delay={(index + 1) * 150}>
                                {renderApplicationItem(item)}
                            </Fade>
                        )
                    })
                    }
                </ul>
            )
        } else {
            return (
                <>
                    <div className="no-applications-message">
                        <h2>Nothing here yet!</h2>
                        <p>If you're in need of assistance, we're here to help. </p>
                        <p>Click the button below to get started filling out an application.</p>
                        <form action="/apply">
                            <button className='approved-button' action="submit">Apply for Assistance</button>
                        </form>
                    </div>
                </>
            )
        }
    }

    if (props.loading) {
        return (
            <>
                <div className="user-applications-container-empty">
                    <h1 className='header'>Your Applications</h1>
                    <div className="no-applications-message">
                        <h2>Loading Your Applications...</h2>
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            <div className={!applications ? "user-applications-container-empty" : "user-applications-container-active"}>
                <h1 className='header'>Your Applications</h1>
                {renderApplications()}
            </div>


        </>
    )
}

export default UserApplicationsContainer;