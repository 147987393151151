import React, { useState } from "react";

import "./EventPicturesChangeStyles.css";
import EventPicturesAdd from "./EventPicturesAdd";
import MainPictureChange from "./MainPictureChange";

const nothing = 0;
const mainChange = 1;
const extraImages = 2;

export default function EventPicturesChange(props) {

    const [showing, setShowing] = useState(nothing);

    // Renders

    const renderImageNav = () => {
        return (
            <>
                <div className="image-nav">
                    <button className="pending-button" onClick={() => {
                        if (showing !== mainChange) {
                            setShowing(mainChange);
                        } else {
                            setShowing(nothing);
                        }
                    }}>Change Main Image</button>
                    <button className="pending-button" onClick={() => {
                        if (showing !== extraImages) {
                            setShowing(extraImages);
                        } else {
                            setShowing(nothing);
                        }
                    }}>Add / Delete Event Images</button>
                </div>
            </>
        )
    }

    const renderSelection = () => {
        switch (showing) {
            case mainChange:
                return <MainPictureChange event={props.event} />

            case extraImages:
                return <EventPicturesAdd event={props.event} />
        }
    }

    return (
        <>
            <div className="pictures-change">
                {renderImageNav()}
                {renderSelection()}
            </div>
        </>
    )
}