export const FamilyMemberOptions = [
    { value: "Parent", label: "Parent" },
    { value: "Spouse", label: "Spouse" },
    { value: "Partner", label: "Partner" },
    { value: "Child", label: "Child" },
    { value: "Sibling", label: "Sibling" },
    { value: "Uncle", label: "Uncle" },
    { value: "Aunt", label: "Aunt" },
    { value: "Cousin", label: "Cousin" },
    { value: "Roommate", label: "Roommate" },
    { value: "Grandparent", label: "Grandparent" },
    { value: "Grandchild", label: "Grandchild" },
    { value: "Niece", label: "Niece" },
    { value: "Nephew", label: "Nephew" },
    { value: "Stepparent", label: "Stepparent" },
    { value: "Stepchild", label: "Stepchild" },
    { value: "In-law", label: "In-law" },
    { value: "Guardian", label: "Guardian" },
    { value: "Housemate", label: "Housemate" },
    { value: "Caretaker", label: "Caretaker" },
];
