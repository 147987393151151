import React, { useState, useLayoutEffect } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';

import { Fade } from 'react-reveal';

import LoadingScreen from '../components/General/LoadingScreen';
import EventsDisplay from '../components/Events/EventsDisplay';

import './EventsStyles.css';

function sortByDate(events) {
    events.sort(function (a, b) {
        var partsA = a.date.split('/'),
            partsB = b.date.split('/');

        var dateA = new Date(partsA[2], partsA[0] - 1, partsA[1]); // Month is 0-indexed
        var dateB = new Date(partsB[2], partsB[0] - 1, partsB[1]);

        return dateA - dateB;
    });
    return events;
}

function useFetchData() {
    axios.defaults.withCredentials = true;
    const [loading, setLoading] = useState(true);
    const [events, setEvents] = useState(null);
    const [activeEvents, setActiveEvents] = useState([]);
    const [inactiveEvents, setInactiveEvents] = useState([]);

    React.useEffect(() => {
        if (!events) {
            setLoading(true);
            axios.get('/api/active-events', { withCredentials: true })
                .then((res) => {
                    const sortedEvents = sortByDate(res.data.events).reverse();
                    setEvents(sortedEvents);
                    const active = sortedEvents.filter(event => event.active);
                    const inactive = sortedEvents.filter(event => !event.active);
                    setActiveEvents(active);
                    setInactiveEvents(inactive);
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                })
        }

    }, []);
    return { loading, events, activeEvents, inactiveEvents };
}

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

export default function Events(props) {
    const { loading, events, activeEvents, inactiveEvents } = useFetchData();

    // Width / Height
    const [width, height] = useWindowSize();
    const userData = props.userData;

    const renderActiveEvents = () => {
        if (activeEvents.length !== 0) {
            return (
                <>
                    <div className={width > 660 ?'events-section-header' : 'events-section-header top-header-margin' }>
                        <h1 className='header'>Upcoming Events</h1>
                    </div>

                    <div>
                        {activeEvents.map((event, index) => {
                            return (
                                <Fade delay={150}>
                                    <EventsDisplay event={event} userData={userData} />
                                </Fade>
                            )
                        })
                        }
                    </div>
                </>

            )
        }

    }

    const renderInactiveEvents = () => {
        if (inactiveEvents.length !== 0) {
            return (
                <>
                    <Fade>
                        <div className='events-section-header'>
                            <h1 className='header'>Past Events</h1>
                        </div>
                    </Fade>


                    <div>
                        {inactiveEvents.map((event, index) => {
                            return (
                                <Fade delay={150}>
                                    <EventsDisplay event={event} />
                                </Fade>

                            )
                        })
                        }
                    </div>
                </>
            )
        }

    }

    // Renders if over 660 width
    const renderHeader = () => {
        if (width > 660) {
            return (
                <Fade>
                    <div className='page-header'>
                        <h1 className='header'>Events That Warm the Heart</h1>
                    </div>
                </Fade>
            )
        }
    }

    if (loading) {
        return <LoadingScreen />
    }

    return (
        <>
            <Helmet>
                <title>Events | Hearts Unite</title>
                {/* Limits User Zoom */}
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
                <meta name="description" content="Learn more about Hearts Unite to Give Support's upcoming fun events and fundraisers." />
                <meta property="og:title" content="Events | Hearts Unite" />
                <meta property="og:image" content="/images/hugs-logo.png" />
            </Helmet>
            <div className='events'>
                {renderHeader()}
                {renderActiveEvents()}
                {renderInactiveEvents()}
            </div>
        </>
    )
}