import React, { useState, useLayoutEffect } from "react";
import axios from 'axios';

import { Fade } from 'react-reveal';

axios.defaults.withCredentials = true;

const nothing = 0;
const unresolved = 1;
const resolved = 2;
const all = 3;

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

export default function ReportControl(props) {
    // Width / Height
    const [width, height] = useWindowSize();

    // Local State for Reports
    const [reports, setReports] = useState(props.reports);

    const [showing, setShowing] = useState(nothing);
    const [opened, setOpened] = useState("");
    const [selectedReport, setSelectedReport] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(false);

    // Update Reports
    const updateReports = (newReport) => {
        // Update Parent State
        props.setReports((prevReports) =>
            prevReports.map((report) =>
            report.token === newReport.token ? newReport : report
            )
        );

        // Update Local State
        setReports((prevReports) =>
            prevReports.map((report) =>
            report.token === newReport.token ? newReport : report
            )
        );
    };

    // API calls

    const changeResolved = (value, token) => {

        const formData = new FormData();
        formData.append('token', token);
        formData.append('resolved', value);

        axios.post('/api/change-report', formData, {
            withCredentials: true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then((res) => {
            if (res.data.status) {
                setSubmitted(true);
                setError(false);
                const newReport = selectedReport;
                newReport.resolved = true;
                setSelectedReport(newReport);
                updateReports(newReport);
            } else {
                setError(true);
            }
        })
    }

    // State Control
    const onUnresolvedClick = () => {
        if (showing !== unresolved) {
            setOpened("");
            setShowing(unresolved);
        } else {
            setOpened("");
            setShowing(nothing);
        }

    }

    const onResolvedClick = () => {
        if (showing !== resolved) {
            setOpened("");
            setShowing(resolved);
        } else {
            setOpened("");
            setShowing(nothing);
        }

    }

    const onAllClick = () => {
        if (showing !== all) {
            setOpened("");
            setShowing(all);
        } else {
            setOpened("");
            setShowing(nothing);
        }
    }

    // Renders

    const renderControl = () => {
        if (width > 980) {
            return (
                <div className='report-control'>
                        <button className='denied-button'
                            onClick={onUnresolvedClick}>
                            View Unresolved
                        </button>

                        <button className='approved-button'
                            onClick={onResolvedClick}>
                            View Resolved
                        </button>

                        <button className='all-button'
                            onClick={onAllClick}>
                            View All
                        </button>
                </div>
            )
        }

        return (
            <>
                <div className='application-control'>
                    <div className='two-button-section'>
                        <button className='denied-button'
                            onClick={onUnresolvedClick}>
                            View Unresolved
                        </button>

                        <button className='approved-button'
                            onClick={onResolvedClick}>
                            View Resolved
                        </button>
                    </div>
                    <div className="one-button-section">
                        <button className='all-button'
                            onClick={onAllClick}>
                            View All
                        </button>
                    </div>
                </div>
            </>
        )
    }

    const renderResolveButton = (resolved, token) => {
        if (resolved) {
            return (
                <div>
                    <button className="denied-button resolve-button" onClick={() => changeResolved(false, token)}>
                        Mark as Unresolved
                    </button>
                </div>

            )
        } else {
            return (
                <div>
                    <button className="approved-button resolve-button" onClick={() => changeResolved(true, token)}>
                        Mark as Resolved
                    </button>
                </div>


            )
        }
    }

    const renderMessage = () => {
        if (submitted) {
            return <h2>Report Resolved!</h2>
        } else {
            if (error) {
                return <h2>Error Updating Report, Try Again Later</h2>
            }
        }

    }

    const renderReport = (report) => {
        if (opened === report.token) {
            return (
                <li className="application-container" key={report.token}>
                    <button className={report.resolved ? "application approved" : "application denied"}
                        onClick={() => {
                            setOpened('')
                            setError(false);
                            setSubmitted(false);
                            setSelectedReport(null);
                        }}>
                        <i class="fa-solid fa-angle-down" /> {report.type} Report {report.token} | Submitted - {report.date} | {report.resolved ? "Resolved" : "Unresolved"}
                    </button>
                    <div className="report-info-list">
                        <div className="application-info-item">
                            <h2>Type: </h2> <p>{report.type}</p>
                        </div>
                        <div className="application-info-item">
                            <h2>Submitted: </h2> <p>{report.date} {report.email ? "by " + report.email : ""}</p>
                        </div>
                        <div className="application-info-item">
                            <h2>Token: </h2> <p>{report.token}</p>
                        </div>

                    </div>

                    <div>
                        <h2>Description:</h2> <p>{report.description}</p>
                    </div>

                    {renderMessage()}

                    {renderResolveButton(report.resolved, report.token)}
                </li>
            )
        } else {
            return (
                <li className='application-container' key={report.token}>
                    <button className={report.resolved ? "application approved" : "application denied"}
                        onClick={() => {
                            setOpened(report.token);
                            setError(false);
                            setSubmitted(false);
                            setSelectedReport(report);
                        }}>
                        <i class="fa-solid fa-angle-right" /> {report.type} Report {report.token} | Submitted - {report.date} | {report.resolved ? "Resolved" : "Unresolved"}
                    </button>
                </li>
            )
        }
    }

    const renderSelection = () => {
        switch (showing) {
            case (nothing):
                return (""
                )
            case (unresolved):
                return (
                    <>
                        <ul className="applications-list">
                            {reports.map((item, index) => {
                                if (!item.resolved) {
                                    return (
                                        <Fade delay={(index + 1) * 150}>
                                            {renderReport(item)}
                                        </Fade>
                                    )
                                }

                            })
                            }
                        </ul>
                    </>
                )

            case (resolved):
                return (
                    <>
                        <ul className="applications-list">
                            {reports.map((item, index) => {
                                if (item.resolved) {
                                    return (
                                        <Fade delay={(index + 1) * 150}>
                                            {renderReport(item)}
                                        </Fade>
                                    )
                                }

                            })
                            }
                        </ul>
                    </>
                )

            case (all):
                return (
                    <>
                        <ul className="applications-list">
                            {reports.map((item, index) => {
                                return (
                                    <Fade delay={(index + 1) * 150}>
                                        {renderReport(item)}
                                    </Fade>
                                )

                            })
                            }
                        </ul>
                    </>
                )
        }
    }

    return (
        <div className='display-container reports-control'>
            <h1 className="header">Reports</h1>
            {renderControl()}
            {renderSelection()}
        </div>
    )
}