import React, { useState } from "react";

import Fade from 'react-reveal/Fade';
import EventSigninForm from "./EventSigninForm";

import "./SelectedEventControl.css";

// Nav values
const nothing = 0;
const newSignin = 1;
const viewSignins = 2;
const submissionCompleted = 3;

export default function SelectedEventControl(props) {
    const [navSelection, setNavSelection] = useState(nothing);
    const selectedEvent = props.selectedEvent;


    const updateDisplayToSubmissionCompleted = () => {
        setNavSelection(submissionCompleted);
    }

    // Renders
    const renderNavSelection = () => {
        switch (navSelection) {
            case (newSignin): {
                return (
                    <>
                        <Fade>
                            <EventSigninForm
                                eventID={selectedEvent._id}
                                guestList={selectedEvent.attendingInfo}
                                width={props.width}
                                updateSelectedGuestList={props.updateSelectedGuestList}
                                updateDisplay={updateDisplayToSubmissionCompleted}
                            />
                        </Fade>
                    </>
                )
            }

            case (viewSignins): {
                return (
                    <>
                        <div className="event-signin-attending-list">
                            <table>
                                <tr>
                                    <th>Name:</th>
                                    <th>Email:</th>
                                    <th>Members in Party:</th>
                                </tr>
                                {selectedEvent.signinData.map((attendee, index) => {
                                    return (
                                        <>
                                            <tr>
                                                <td>{attendee.firstName} {attendee.lastName}</td>
                                                <td>{attendee.email}</td>
                                                <td>{attendee.groupMembers}</td>
                                            </tr>
                                        </>
                                    )
                                })}
                            </table>
                        </div>

                    </>
                )
            }

            case submissionCompleted: {
                return (
                    <div>
                        <h1 className="header">Thank You for Signing in!</h1>
                        <p>Your sign in has been received, enjoy the event!</p>
                    </div>
                )
            }
        }
    }

    return (
        <>
            <div className="event-signin-container">
                <div className="event-signin-back-button">
                    <div className="back-button">
                        <button className="denied-button" onClick={props.goBack}><i class="fa-solid fa-left-long" /> Back</button>
                    </div>
                </div>

                <h1 className="header">{selectedEvent.name} Sign In</h1>

                <div className="event-signin-nav">
                    <button className="approved-button" onClick={() => {
                        setNavSelection(newSignin);
                    }}>Sign In New Guest</button>
                    <button className="all-button" onClick={() => {
                        setNavSelection(viewSignins);
                    }}>View All Signed In</button>
                </div>
                {renderNavSelection()}
            </div>
        </>
    )
}