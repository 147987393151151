import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import Select from 'react-select';
import "./FamilyMemberContainerStyles.css";
import { FamilyMemberOptions } from "./DropdownOptions/FamilyMemberOptions";

const numbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
const symbols = [
    '<', '>', '&', '"', "'", '/', '\\', ';', '%', '$', '#', '{', '}', '[', ']',
    '(', ')', '=', '+', '-', '*', '`', '^', '|', '~', '!', '?', ':', '.', ',', '@'
];

export default function FamilyMemberContainer(props) {
    const [familyMember, setFamilyMember] = useState(null);
    const [name, setName] = useState("");
    const [birthday, setBirthday] = useState("");

    const filterNumbersAndSymbols = (input) => {
        for (var i = 0; i < input.length; i++) {
            if (numbers.includes(input[i]) || symbols.includes(input[i])) {
                return false;
            }
        }

        return true;
    }

    const selectStyles = {
        option: (provided, state) => ({
            ...provided,
            fontWeight: state.isSelected ? "bold" : "normal",
            fontSize: props.width <= 1260 ? "1.2rem" : "1.4rem",
            color: "#2d3436",
            backgroundColor: "#fff",
            "&:hover": {
                backgroundColor: "#f0f0f0",
            },
        }),
        control: (provided, state) => ({
            ...provided,
            borderColor: state.isFocused ? "#2d3436" : "#fff",
            margin: "12px 0 12px",
            height: props.width <= 1260 ? "48px" : "55px",
        }),
    };


    const index = props.index;

    const verifyName = () => {
        if (name.length < 2 || name.length > 20) {
            return false;
        }

        return true;
    }

    const verifyBirthday = () => {
        if (birthday.length === 10) {
            return true;
        }

        return false;
    }


    return (
        <>
            <div className="family-member-add-container">
                <div className="section-item">
                    <label>
                        Relationship:
                        <Select
                            styles={selectStyles}
                            menuPortalTarget={document.body}
                            menuPosition={'fixed'}
                            value={familyMember}
                            onChange={(option) => {
                                setFamilyMember(option);
                                props.updateRelation(index, option.value);
                            }} options={FamilyMemberOptions} />
                    </label>
                </div>

                <div className="section-item">

                    <label for="name">
                        Name: {verifyName() ? <i class="fa-solid fa-check green-check"></i> : <i class="fa-thin fa-asterisk red-asterisk" />}

                        <InputText id='name' value={name} onChange={(event) => {
                            if (filterNumbersAndSymbols(event.target.value)) {
                                // Update local value
                                setName(event.target.value);
                                // Update parent value

                                props.updateName(index, event.target.value);
                            }
                        }} />
                    </label>
                </div>

                <div className="section-item">

                    <label for="birthday">
                        Birthday: {verifyBirthday() ? <i class="fa-solid fa-check green-check"></i> : <i class="fa-thin fa-asterisk red-asterisk" />}

                        <InputMask mask="99/99/9999" slotChar="mm/dd/yyyy" value={birthday} onChange={(event) => {
                            // Update local value
                            setBirthday(event.target.value);
                            // Update parent value
                            props.updateBirthday(index, event.target.value)
                        }} />
                    </label>

                </div>

                <div className="section-item family-member-remove-button-container">
                    <button className="denied-button" onClick={() => {
                        props.removeSelectedFamilyMember(index)
                    }}>Remove</button>
                </div>
            </div>
        </>
    )
}