import React, { useState, useLayoutEffect } from "react"
import './ApplicationPreview.css';

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

export default function ApplicaitonPreview(props) {
    const [width, height] = useWindowSize();
    const firstName = props.firstName;
    const lastName = props.lastName;
    const gender = props.gender;
    const birthday = props.birthday;
    const email = props.email;
    const phone = props.phone;
    const type = props.type;
    const method = props.method;
    const amount = props.amount;
    const status = props.status;
    const details = props.details;

    // Normal sizing
    if (width > 780) {
        return (
            <>
                <div className='application-preview'>
                    <div className='application-preview-item'>
                        <h2>First Name: </h2> <p>{firstName}</p>
                    </div>

                    <div className='application-preview-item'>
                        <h2>Last Name: </h2> <p>{lastName}</p>
                    </div>

                    <div className='application-preview-item'>
                        <h2>Gender: </h2> <p>{gender}</p>
                    </div>

                    <div className='application-preview-item'>
                        <h2>Birthday: </h2> <p>{birthday}</p>
                    </div>

                    <div className='application-preview-item'>
                        <h2>Email: </h2> <p>{email}</p>
                    </div>

                    <div className='application-preview-item'>
                        <h2>Phone: </h2> <p>{phone}</p>
                    </div>

                    <div className='application-preview-item'>
                        <h2>Type of Assistance: </h2> <p>{type}</p>
                    </div>

                    <div className='application-preview-item'>
                        <h2>Assistance Method: </h2> <p>{method}</p>
                    </div>

                    <div className='application-preview-item'>
                        <h2>Assistance Amount: </h2> <p>${amount}</p>
                    </div>

                    <div className='application-preview-item'>
                        <h2>Status: </h2> <p>{status}</p>
                    </div>
                </div>

                <div className='description-container'>
                    <h2>Application Details:</h2>
                    <p>{details}</p>
                </div>
            </>
        )
    }

    // Mobile Sizing
    return (
        <>
            <div className='mobile-application-preview'>
                <div className='application-preview-item'>
                    <h2>Name: </h2> 
                    <p>{firstName + ' ' + lastName}</p>
                </div>

                <div className='application-preview-item'>
                    <h2>Birthday: </h2> <p>{birthday}</p>
                </div>

                <div className='application-preview-item'>
                    <h2>Email: </h2> <p>{email}</p>
                </div>

                <div className='application-preview-item'>
                    <h2>Type of Assistance: </h2> <p>{type}</p>
                </div>

                <div className='application-preview-item'>
                    <h2>Assistance Method: </h2> <p>{method}</p>
                </div>

                <div className='application-preview-item'>
                    <h2>Assistance Amount: </h2> <p>${amount}</p>
                </div>

                <div className='application-preview-item'>
                    <h2>Status: </h2> <p>{status}</p>
                </div>
            </div>

            <div className='description-container'>
                <h2>Application Details:</h2>
                <p>{details}</p>
            </div>
        </>
    )

}